<template>
    <div class="container">
        <div class="operation">
            <div class="tab" v-bind:class="{active:activeName=='sidoc'}" @click="loadLogList('sidoc')">Sidoc服务日志</div>
            <div class="tab" v-bind:class="{active:activeName=='frp'}" @click="loadLogList('frp')">Frp服务日志</div>
            <div class="tab" v-bind:class="{active:activeName=='frp-nohup'}" @click="loadLogList('frp-nohup')">Frp nohup日志</div>
        </div>
        <div v-show="!isLoading" class="data">
            <div class="logList">
                <div class="item" v-bind:class="{active:logListItemActive==item}" v-for="(item,index) in logList" :key="index" @click="loadLogContent(item)">{{item}}</div>
            </div>
            <div class="logContent"><pre>{{data}}</pre></div>
        </div>
        <div v-show="isLoading">加载中...</div>
    </div>
</template>

<script>

import { loadLogListPost,loadLogContentPost } from '@/service/api'
export default {
    data(){
        return {
            activeName:"sidoc",
            isLoading:false,

            logList:[],
            logListItemActive:"",
            logFolder:"",
            data:"",
        }
    },
    mounted(){
        this.loadLogList(this.activeName);
    },
    methods:{
        // 加载日志列表
        loadLogList(name){

            this.activeName = name;
            this.data = "";

            var params = {"key":this.activeName};
            this.isLoading = true;
            loadLogListPost(params).then(res => {
                if(res.status_code == 0){
                    if(res.data){
                        this.logList = res.data.logList;
                        this.logFolder = res.data.logFolder;
                    }else{
                        this.logList = [];
                        this.logFolder = [];
                    }

                    if(this.logList.length > 0){
                        this.loadLogContent(this.logList[0]);
                    }  
                }
            }).finally(()=>{
               this.isLoading = false;
            });
        },
        // 加载日志内容
        loadLogContent(item){

            this.logListItemActive = item;

            if(this.logFolder.substr(this.logFolder.length-1,1) != "/"){
                this.logFolder =  this.logFolder + "/";
            }
            var params = {"path":this.logFolder + item,"key":this.activeName};
            loadLogContentPost(params).then(res => {
                if(res.status_code == 0){
                    this.data = res.data;
                }
            });
        }
    }

}
</script>
<style scoped>
.operation{
    display:flex;
    border-bottom: solid 1px #509dff;
}
.operation .tab{
    cursor: pointer;
    padding:8px 6px;
    margin: 0px 6px;
}
.operation .tab.active{
    color: #509dff;
    font-weight: bold;
}

.data{
    height: calc(100% - 38px);
}

.data .logList{
    overflow-y: auto;
    vertical-align: top;
    display:inline-block;
    width: 180px;
    height: 100%;
    border-right: solid 1px #509dff59;
}
.data .logList .item{
    padding: 6px 8px;
    cursor: pointer;
}
.data .logList .item:hover{
    background: #f1f1f1;
}
.data .logList .item.active{
    font-weight: bold;
    background: #cdeaff;
}
.data .logContent{
    overflow: auto;
    height:100%;

    vertical-align: top;
    display:inline-block;
    width:calc(100% - 182px);
}
.data pre{
    margin: 0px;
}


</style>